<template>
  <div v-if="respData">
    <b-row
      v-if="$ability.can('read', this.$route.meta.resource)"
      class="match-height"
    >
      <b-col
        cols="12"
        class="mb-2"
      >
        <b-button
          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
          variant="primary"
          @click="goUpload"
        >
          Upload Image Application
        </b-button>
      </b-col>
      <b-col md="6">
        <b-card title="WebSite Version">
          <b-form-group
            label-cols="2"
            label-cols-lg="2"
            :label="$t('Version') + ' :'"
            label-for="input-lg"
          >
            <b-form-input
              id="input-lg"
              v-model="currentVersion"
              :placeholder="$t('Version')"
            />
          </b-form-group>
          <b-button
            size="sm"
            variant="primary"
            @click="inputChange('currentVersion')"
          >
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon
              icon="SaveIcon"
              size="12"
            />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Web Application">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Dev :
            </b-card-text>
            <b-form-checkbox
              :checked="respData['dev'] !== null ? respData['dev'] : false"
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('dev', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["dev"] !== null
                  ? respData["dev"] === true
                    ? "อยู่ในการพัฒนา"
                    : "สาธารณะ"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>

          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Maintenance :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['maintenance'] !== null
                  ? respData['maintenance']
                  : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('maintenance', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["maintenance"] !== null
                  ? respData["maintenance"] === true
                    ? "อยู่ในระหว่างการซ่อมบำรุง"
                    : "สาธารณะ"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="Language">
          <b-row class="mt-1">
            <b-row
              v-for="(item, index) in language"
              :id="item._id"
              :key="item._id"
              ref="row"
            >
              <!-- Cost -->
              <b-col md="3">
                <b-form-group
                  label="Name"
                  label-for="name"
                >
                  <b-form-input
                    id="name"
                    v-model="item.name"
                    type="text"
                    placeholder="Name"
                  />
                </b-form-group>
              </b-col>

              <!-- Quantity -->
              <b-col md="3">
                <b-form-group
                  label="Lang"
                  label-for="lang"
                >
                  <b-form-input
                    id="lang"
                    v-model="item.lang"
                    type="text"
                    placeholder="Lang"
                  />
                </b-form-group>
              </b-col>

              <b-col
                md="3"
                class="mb-50"
              >
                <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-0 mt-md-2"
                  @click="removeItem(index)"
                >
                  <feather-icon
                    icon="XIcon"
                    class="mr-25"
                  />
                  <span>{{ $t("Delete") }}</span>
                </b-button>
              </b-col>
              <b-col
                md="3"
                class="mb-50"
              >
                <b-button
                  v-if="language.length === index + 1"
                  v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                  size="sm"
                  variant="primary"
                  class="mt-0 mt-md-2"

                  @click="repeateAgain"
                >
                  <feather-icon
                    icon="PlusIcon"
                    class="mr-25"
                  />
                  <span>{{ $t('Add New') }}</span>
                </b-button>
              </b-col>

              <b-col cols="12">

                <hr>

              </b-col>
            </b-row>

          </b-row>
          <b-button
            v-if="language.length<1"
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            size="sm"
            variant="primary"
            @click="repeateAgain"
          >
            <feather-icon
              icon="PlusIcon"
              class="mr-25"
            />
            <span>{{ $t('Add New') }}</span>
          </b-button>
          <b-button
            size="sm"
            variant="primary"
            @click="inputChange('language')"
          >
            <span class="mr-25 align-middle"> {{ $t("Save") }}</span>
            <feather-icon
              icon="SaveIcon"
              size="12"
            />
          </b-button>
        </b-card>
      </b-col>
      <b-col md="6">
        <b-card title="System">
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Bank :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['bank'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.bank', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData["system"]["bank"] == true
                    ? "เปิดระบบธนาคาร"
                    : "ปิดระบบธนาคาร"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Money :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null ? respData['system']['money'] : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.money', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData["system"]["money"] == true
                    ? "เปิดระบบเงินสด"
                    : "ปิดระบบเงินสด"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Promptpay :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null
                  ? respData['system']['promptpay']
                  : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.promptpay', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData["system"]["promptpay"] == true
                    ? "เปิดระบบพร้อมเพย์"
                    : "ปิดระบบพร้อมเพย์"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Credit :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null
                  ? respData['system']['credit']
                  : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.credit', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData["system"]["credit"] == true
                    ? "เปิดระบบเครดิต"
                    : "ปิดระบบเครดิต"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Paypal :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null
                  ? respData['system']['paypal']
                  : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.paypal', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData["system"]["paypal"] == true
                    ? "เปิดระบบเพย์พาล"
                    : "ปิดระบบเพย์พาล"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
          <b-row class="mt-1">
            <b-card-text class="ml-2 mr-2">
              Wallet :
            </b-card-text>
            <b-form-checkbox
              :checked="
                respData['system'] != null
                  ? respData['system']['wallet']
                  : false
              "
              class="custom-control-primary"
              name="check-button"
              switch
              @change="switchChange('system.wallet', $event)"
            />
            <b-card-text class="ml-2">
              {{
                respData["system"] != null
                  ? respData["system"]["wallet"] == true
                    ? "เปิดระบบกระเป๋าเงิน"
                    : "ปิดระบบกระเป๋าเงิน"
                  : "ยังไม่ได้ตั้งค่า"
              }}
            </b-card-text>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BButton,
  BCard,
  BCardText,
  BRow,
  BCol,
  BFormCheckbox,
  BFormGroup,
  BFormInput,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import Ripple from 'vue-ripple-directive'
import store from '@/store'
import StoreModule from '@/store/root/system'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

const STORE_MODULE_NAME = 'system'

export default {
  components: {
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    BButton,
    BCard,
    BCardText,
    BRow,
    BCol,
    BFormCheckbox,
    BFormGroup,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {}
  },
  computed: {
    respData() {
      return store.state[STORE_MODULE_NAME].respData
    },
    currentVersion: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.currentVersion
          ? store.state[STORE_MODULE_NAME].respData.currentVersion
          : ''
      },
      // setter
      set(newValue) {
        const obj = {
          name: 'currentVersion',
          value: newValue,
        }
        store.commit(`${STORE_MODULE_NAME}/INPUT_CHANGE`, obj)
      },
    },
    language: {
      get() {
        return store.state[STORE_MODULE_NAME].respData.language
          ? store.state[STORE_MODULE_NAME].respData.language
          : []
      },
    },
  },
  created() {
    // Register module
    if (!store.hasModule(STORE_MODULE_NAME)) {
      store.registerModule(STORE_MODULE_NAME, StoreModule)
    }
    store
      .dispatch(`${STORE_MODULE_NAME}/get`)
      .then(result => {
        console.log('fetch Success : ', result)
      })
      .catch(error => {
        console.log('fetchUsers Error : ', error)
        this.$toast({
          component: ToastificationContent,
          position: 'bottom-right',
          props: {
            title: this.$t('Error'),
            icon: 'ErrorIcon',
            variant: 'danger',
            text: this.$t(error.response.data.message),
          },
        })
      })
  },
  destroyed() {
    if (store.hasModule(STORE_MODULE_NAME)) {
      store.unregisterModule(STORE_MODULE_NAME)
    }
  },
  methods: {
    repeateAgain() {
      this.language.push({
        name: '',
        lang: '',
      })
    },
    removeItem(index) {
      this.language.splice(index, 1)
    },
    goUpload() {
      this.$router.push({ path: '/root/system/upload' })
    },
    inputChange(name) {
      const obj = {
        currentVersion: store.state[STORE_MODULE_NAME].respData.currentVersion,
        language: store.state[STORE_MODULE_NAME].respData.language,
      }
      if (name === 'currentVersion') {
        obj.currentVersion = this.currentVersion
      } else if (name === 'language') {
        obj.language = this.language
      }
      console.log(name)

      store
        .dispatch(`${STORE_MODULE_NAME}/inputChange`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
    switchChange(name, status) {
      console.log(name, status)
      const obj = {
        name,
        status,
      }
      store
        .dispatch(`${STORE_MODULE_NAME}/switchChange`, obj)
        .then(result => {
          console.log('fetch Success : ', result)
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
